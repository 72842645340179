.footer{
  padding: 30px 0 10px 0;
  color: #ffffff;
  background: #292b30;
  .container{
    max-width: 1700px;
    width: auto;
  }
}

.footer-content{
  display: flex;
  justify-content: space-between;
  @media screen and (max-width: 1000px){
    flex-wrap: wrap;
  }
  &__col{
    flex-grow: 1;
    padding: 0 10px 25px 0;
    &--itarian-logo{
      flex-grow: 2;
    }
    //@media screen and (max-width: 1000px){
    //  &:nth-child(1){
    //    width: 100%;
    //  }
    //}
    //@media screen and (max-width: 991px){
    //  &:nth-child(2),&:nth-child(3),
    //  &:nth-child(4),&:nth-child(5){
    //    width: 50%;
    //  }
    //}
    //@media screen and (max-width: 420px){
    //  &:nth-child(2),&:nth-child(3),
    //  &:nth-child(4),&:nth-child(5){
    //    width: 100%;
    //  }
    //}
    //
    //
    //.headline{
    //  margin: 0 0 5px 0;
    //  font-weight: 600;
    //  opacity: 0.3;
    //  text-transform: uppercase;
    //}
    //.link-list{
    //  list-style: none;
    //  padding: 0;
    //  &__social-links{
    //    li{
    //      width: 25px;
    //    }
    //  }
    //  &__inline{
    //    li{
    //      display: inline-block;
    //    }
    //  }
    //  a{
    //    color: #ffffff;
    //    opacity: 0.3;
    //    transition: 0.2s all;
    //    &:hover{
    //      opacity: 1;
    //      text-decoration: none;
    //    }
    //  }
    //}

    .list-inline{
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      li{
        white-space: nowrap;
        a:hover{
          color: #41a4f0;
        }
      }
    }
  }
  .copyright{
    margin-top: 7px;
    font-size: 13.7px;
    color: #5a5d63;
  }
}


.icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: url('/frontend/web/images/itarian/social-icons.png') no-repeat 1px center transparent;
  opacity: 0.3;
  &:hover {
    opacity: 1;
  }
  &-googleplus{
    background-position: -32px center;
  }

  &-linkedin{
    background-position: -65px center;
  }

  &-twitter{
    background-position: -98px center;
  }

  &-facebook{
    background-position: -133px center;
  }

}



